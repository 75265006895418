import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Map from '../components/map'

const ContactPage = () => (
  <Layout>
    <SEO title="Kontakt na masážní salon Vimperk" />
    <script type="text/javascript" src="https://api.mapy.cz/loader.js"></script>
    <script>Loader.load()</script>

    <div className="container">
      <h1>Kontakt</h1>

      <p>
        Mária Vacíková<br/>
        Pro objednání prosím <a className="link" href="tel:+420776009508">volejte 776 009 508</a>.
        {/* <a href="mailto:info@salon-dimension.cz">info@salon-dimension.cz</a> */}
      </p>

      Provozovna:
      <address>
        Mírová 469<br/>
        385 01, Vimperk<br/>
      </address>

      <Map/>

      <p style={{paddingTop: '3em'}}>
        <small>
          IČ: 72097655<br/>
          IČ provozovny: 1003871054<br/>
          FO zapsána v živnostenském rejstříku od 28.3.2001, Městský úřad Vimperk.
        </small>
      </p>
    </div>
  </Layout>
)

export default ContactPage
